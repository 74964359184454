import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Backdrop,
  Box,
} from "@mui/material";
import { formatDistanceToNow, parseISO } from "date-fns";
import DOMPurify from 'dompurify';

function JobDialog({ job, open, onClose }) {
  if (!job) return null;

  const renderWorkType = (workType) => {
    const types = Array.isArray(workType) ? workType : [workType];
    return types.map((type, index) => (
      <Box
        key={index}
        mt={1}
        mr={0.5}
        sx={{
          display: "inline-block",
          padding: "0px 4px",
          border: "2px solid lightgrey",
          borderRadius: "4px",
          backgroundColor: "#efeaef",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {type}
        </Typography>
      </Box>
    ));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Lightly shaded backdrop
          },
        },
      }}
      PaperProps={{
        sx: {
          boxShadow: "none", // Remove shadow from the dialog
          borderRadius: "16px", // Rounded corners
        },
      }}
    >
      <DialogTitle mt={2}>{job.title}</DialogTitle>
      <DialogContent
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        <Box
          mt={0.5}
          mr={0.5}
          sx={{
            display: "inline-block",
            padding: "0px 4px",
            border: "2px solid lightgrey",
            borderRadius: "4px",
            backgroundColor: "#f3f7f8",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {job.company}
          </Typography>
        </Box>

        {/* Location display */}
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {Array.isArray(job.location) ? (
            job.location.map((loc, index) => (
              <Box
                key={index}
                sx={{
                  display: "inline-block",
                  padding: "0px 4px",
                  border: "2px solid lightgrey",
                  borderRadius: "4px",
                  backgroundColor: "#f3f7f8",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {loc}
                </Typography>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                display: "inline-block",
                padding: "0px 4px",
                border: "2px solid lightgrey",
                borderRadius: "4px",
                backgroundColor: "#f3f7f8",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {job.location}
              </Typography>
            </Box>
          )}
        </Box>
        <div>{renderWorkType(job.work_type)}</div>
        {job.salary && job.salary !== "" && job.salary !== "No salary found" ? (
          <div>
            <Box
              mt={1}
              mr={0.5}
              sx={{
                display: "inline-block",
                padding: "0px 4px", // Add some padding inside the box
                border: "2px solid lightgrey", // Border around the box
                borderRadius: "4px", // Rounded corners
                backgroundColor: "#eaf3e6",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Salary: {job.salary}
              </Typography>
            </Box>
          </div>
        ) : null}
        <Typography variant="body2" component="div" mt={5} mb={5} mr={5} ml={5} gutterBottom>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.job_description) }} />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Added {formatDistanceToNow(parseISO(job.date_posted))} ago
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button
          href={job.job_link}
          target="_blank"
          color="primary"
          variant="contained"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobDialog;
