import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = () => setGoogleScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handleGoogleSignIn = async (response) => {
      console.log("Google Sign-In response", response);
  
      // Extract the ID token from the response
      const idToken = response.credential; // This is the ID token
  
      try {
        // Send the ID token to your backend for verification and login
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/google-login`,
          {
            credential: idToken,
          }
        );
  
        if (res.status === 200) {
          const token = res.data.access_token; // Get the access token from the response
  
          // Store the token in local or session storage
          localStorage.setItem("token", token); // or sessionStorage.setItem("token", token);
  
          setSuccess("Login successful. Redirecting to jobs...");
          setError(""); // Clear any previous errors
  
          // Redirect to /jobs after a short delay
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        } else {
          setError("Login failed");
        }
      } catch (err) {
        console.error(err);
        setError("An error occurred during Google login");
      }
    };
    if (googleScriptLoaded && window.google) {
      window.google.accounts.id.initialize({
        client_id:
          "758941406811-4s1b90kq5hji7f1cg2sd9r5selcorv7t.apps.googleusercontent.com",
        callback: handleGoogleSignIn,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInDiv"),
        { theme: "outline", size: "large" }
      );
    }
  }, [googleScriptLoaded, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Simple validation
    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        {
          email,
          password,
          remember_me: rememberMe,
        }
      );

      if (response.status === 200) {
        // Handle successful login

        const token = response.data.access_token;

        if (rememberMe) {
          localStorage.setItem("token", token); // Store token in local storage
        } else {
          sessionStorage.setItem("token", token); // Store token in session storage
        }

        setSuccess("Login successful. Redirecting to jobs...");
        setError(""); // Clear any previous errors
        // Redirect to /jobs after a short delay
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      } else {
        setError("Login failed");
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred during login");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <IconButton
          onClick={() => navigate("/jobs")}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon style={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember Me"
          />
          {success && (
            <Alert severity="success" align="center">
              {success}
            </Alert>
          )}
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "20px" }}
          >
            Login
          </Button>
        </form>
        <Box mt={2} textAlign={"center"}>
          <Typography>
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </Typography>
        </Box>
        <Typography mt={2} variant="h6" align="center">
          OR
        </Typography>
        <Box mt={2} mb={2}>
          <div id="googleSignInDiv"></div>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
