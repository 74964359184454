import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  FormGroup,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Simple validation
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!termsAccepted || !privacyAccepted) {
      setError(
        "You must accept the Terms and Conditions and Privacy Policy to sign up"
      );
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, {
        first_name: firstName, // Use snake_case to match backend field
        email,
        password,
      });

      if (response.status === 201) {
        // Handle successful sign-up
        setSuccess("Sign up successful. Redirecting to login...");
        setError("");
        // Redirect to login after short delay
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError("Sign-up failed");
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred during sign-up");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <IconButton
          onClick={() => navigate("/jobs")}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon style={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          Sign Up
        </Typography>
        <form onSubmit={handleSignUp}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                />
              }
              label={
                <span>
                  I agree to the{" "}
                  <Link href="/terms" target="_blank">
                    Terms and Conditions
                  </Link>{" "}
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyAccepted}
                  onChange={(e) => setPrivacyAccepted(e.target.checked)}
                  required
                />
              }
              label={
                <span>
                  I agree to the{" "}
                  <Link href="/privacy" target="_blank">
                    Privacy Policy
                  </Link>{" "}
                </span>
              }
            />
          </FormGroup>
          {success && (
            <Alert severity="success" align="center">
              {success}
            </Alert>
          )}
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "20px" }}
          >
            Sign Up
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SignUpForm;
